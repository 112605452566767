import React from 'react';
import EventResultsContainer from './EventResultsContainer';
import Layout from '../Layout';

const EventResultsPage = ({ match }) => {
    const { params } = match;
    const { eventId, classShortCode } = params;
    return (
        <Layout eventId={eventId}>
            <EventResultsContainer eventId={eventId} classShortCode={classShortCode} />
        </Layout>
    );
};

export default EventResultsPage;