import React from 'react';
import { map } from 'lodash';
import { Table, Col, Container, Row } from 'react-bootstrap';
import moment from 'moment';
import formatLapTime from '../../helpers/lapTimeFormatter';

const getRowColor = (statusId, lapScore) => {
    if (statusId === 8 || statusId === 16) {
        return '#FFEEEE';
    } else if (lapScore !== null) {
        return '#EEFFEE';
    }

    return '#FFFFFF';
};

const CompetitorSession = ({ competitorSession, shouldDisplayPaxFields }) => {
    return (
        <Container fluid>
            <Row>
                <Col sm={12}>
                    {competitorSession.sessionName} ({competitorSession.sessionStatusName})
                </Col>
            </Row>
            <Row>
                <Col sm={12}>
                    <Table bordered>
                        <thead>
                            <tr>
                                <th style={{ width: '20%', backgroundColor: '#EEEEEE' }}>
                                    Laptime
                                </th>
                                {
                                    shouldDisplayPaxFields
                                        ?
                                        <th style={{ width: '20%', backgroundColor: '#EEEEEE' }}>
                                            Pax Time
                                        </th>
                                        : ''
                                }
                                <th style={{ width: '20%', backgroundColor: '#EEEEEE' }}>
                                    Time of Day
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {map(competitorSession.sessionLaps, (lap, index) => {
                                return (
                                    <tr key={index} style={{ backgroundColor: getRowColor(lap.statusId, lap.score)}}>
                                        <td>
                                            {formatLapTime(lap.timeInSeconds)} {lap.statusId !== 2 ? `(${lap.statusName})` : ''}
                                            {lap.score
                                                ? <span style={{ fontWeight: 'bold' }}>({lap.score.toFixed(3)}{lap.isExhibition ? '*' : ''}{lap.isPenalizedBySteward ? '!' : ''})</span>
                                                : ''}
                                        </td>
                                        {
                                            shouldDisplayPaxFields
                                                ? <td>{lap.paxTime}</td>
                                                : ''
                                        }
                                        <td>{moment.utc(lap.endUtcTimestamp).local().format('h:mm:ss a')}</td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </Table>
                </Col>
            </Row>
        </Container>
    );
};

export default CompetitorSession;
