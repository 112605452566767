import React from 'react';
import { map } from 'lodash';
import { Col, Container, Row } from 'react-bootstrap';
import ClassResult from './ClassResult';

const EventResults = ({ results, eventId, shouldDisplayPaxFields }) => {
    return (
        <Container fluid>
            {map(results, classResult => {
                return (
                    <Row key={classResult.classShortCode}>
                        <Col sm={12}>
                            <ClassResult classResult={classResult} eventId={eventId} shouldDisplayPaxFields={shouldDisplayPaxFields}/>
                        </Col>
                    </Row>
                );
            })}
        </Container>
    );
};

export default EventResults;
