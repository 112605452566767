import React from 'react';
import SeriesDetailsContainer from './SeriesDetailsContainer';
import SimpleLayout from '../SimpleLayout';

const SeriesDetailsPage = ({ match }) => {
    const { params } = match;
    const { seriesId } = params;
    return (
        <SimpleLayout>
            <SeriesDetailsContainer seriesId={seriesId} />
        </SimpleLayout>
    );
};

export default SeriesDetailsPage;