import React from 'react';
import styled from 'styled-components';
import { Table, Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import formatLapTime from '../../helpers/lapTimeFormatter';

const getRankOrdinal = rank => {
    var s = ['th', 'st', 'nd', 'rd'],
        v = rank % 100;
    return rank + (s[(v - 20) % 10] || s[v] || s[0]);
};

const TextLink = styled(Link)`
    font-weight: bold;
    color: ${({ theme }) => theme.red};

    &:hover {
        cursor: pointer;
        text-decoration: underline;
        color: ${({ theme }) => theme.red};
    }
`;

const CompetitorResult = ({ eventId, competitorResult, shouldDisplayPaxFields }) => {
    const boldStyle = {
        fontWeight: 'bold',
    };
    const bigFontStyle = {
        fontSize: '125%',
    };
    
    return (
        <Container fluid>
            <Row>
                <Col sm={1}>
                    <span style={bigFontStyle}>{getRankOrdinal(competitorResult.rank)}</span>
                </Col>
                <Col sm={2}>
                    <TextLink to={`/events/${eventId}/competitor/${competitorResult.competitorId}/details`}>
                        {competitorResult.competitorName}
                    </TextLink>
                </Col>
                <Col sm={3}>
                    <span style={boldStyle}>#{competitorResult.carNumber}</span> {competitorResult.car}
                </Col>
                {
                    shouldDisplayPaxFields
                        ? <Col sm={2}>
                            <span style={boldStyle}>PI (PAX):</span> {competitorResult.performanceIndex} ({competitorResult.paxFactor})
                        </Col>
                        : ''
                }
                <Col sm={2}>
                    <span style={boldStyle}>Class Target:</span> {formatLapTime(competitorResult.classTargetTimeInSeconds)}
                </Col>
                <Col sm={2}>
                    <span style={boldStyle}>Overall Target:</span> {formatLapTime(competitorResult.overallTargetTimeInSeconds)}
                </Col>
            </Row>
            <Row>
                <Col sm={1} />
                <Col sm={11}>
                    <Table bordered>
                        <thead>
                            <tr>
                                <th style={{ width: '20%', backgroundColor: '#EEEEEE' }}>1</th>
                                <th style={{ width: '20%', backgroundColor: '#EEEEEE' }}>2</th>
                                <th style={{ width: '20%', backgroundColor: '#EEEEEE' }}>3</th>
                                <th style={{ width: '20%', backgroundColor: '#EEEEEE' }}>4</th>
                                <th style={{ width: '20%', backgroundColor: '#EEEEEE' }}>5</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    {
                                        competitorResult.competitorLaps && competitorResult.competitorLaps[0] && competitorResult.competitorLaps[0].timeInSeconds
                                            ? formatLapTime(competitorResult.competitorLaps[0].timeInSeconds)
                                            : '-'
                                    }
                                    {
                                        competitorResult.competitorLaps && competitorResult.competitorLaps[0] && competitorResult.competitorLaps[0].score
                                            ? <span style={{ fontWeight: 'bold' }}>
                                                ({competitorResult.competitorLaps[0].score.toFixed(3)}
                                                {competitorResult.competitorLaps[0].isExhibition ? '*' : ''}
                                                {competitorResult.competitorLaps[0].isPenalizedBySteward ? '!' : ''})
                                            </span>
                                            : ''
                                    }
                                </td>
                                <td>
                                    {
                                        competitorResult.competitorLaps && competitorResult.competitorLaps[1] && competitorResult.competitorLaps[1].timeInSeconds
                                            ? formatLapTime(competitorResult.competitorLaps[1].timeInSeconds)
                                            : '-'
                                    }
                                </td>
                                <td>
                                    {
                                        competitorResult.competitorLaps && competitorResult.competitorLaps[2] && competitorResult.competitorLaps[2].timeInSeconds
                                            ? formatLapTime(competitorResult.competitorLaps[2].timeInSeconds)
                                            : '-'
                                    }
                                </td>
                                <td>
                                    {
                                        competitorResult.competitorLaps && competitorResult.competitorLaps[3] && competitorResult.competitorLaps[3].timeInSeconds
                                            ? formatLapTime(competitorResult.competitorLaps[3].timeInSeconds)
                                            : '-'
                                    }
                                </td>
                                <td>
                                    {
                                        competitorResult.competitorLaps && competitorResult.competitorLaps[4] && competitorResult.competitorLaps[4].timeInSeconds
                                            ? formatLapTime(competitorResult.competitorLaps[4].timeInSeconds)
                                            : '-'
                                    }
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                </Col>
            </Row>
        </Container>
    );
};

export default CompetitorResult;
