import eventResource from '../resources/eventResource';
import eventResultsResource from '../resources/eventResultsResource';
import competitorDetailsResource from '../resources/competitorDetailsResource';
import seriesDetailsResource from '../resources/seriesDetailsResource';
import eventCompetitorBestLapsResource from '../resources/eventCompetitorBestLapsResource';

// Action Types
const FETCH_CURRENT_EVENT_START = 'FETCH_CURRENT_EVENT_START';
const FETCH_CURRENT_EVENT_SUCCESS = 'FETCH_CURRENT_EVENT_SUCCESS';
const FETCH_CURRENT_EVENT_FAILURE = 'FETCH_CURRENT_EVENT_FAILURE';

const SET_SELECTED_EVENT_ID = 'SET_SELECTED_EVENT_ID';

const FETCH_SELECTED_EVENT_DETAILS_START = 'FETCH_SELECTED_EVENT_DETAILS_START';
const FETCH_SELECTED_EVENT_DETAILS_SUCCESS = 'FETCH_SELECTED_EVENT_DETAILS_SUCCESS';
const FETCH_SELECTED_EVENT_DETAILS_FAILURE = 'FETCH_SELECTED_EVENT_DETAILS_FAILURE';

const FETCH_SELECTED_EVENT_RESULTS_START = 'FETCH_SELECTED_EVENT_RESULTS_START';
const FETCH_SELECTED_EVENT_RESULTS_SUCCESS = 'FETCH_SELECTED_EVENT_RESULTS_SUCCESS';
const FETCH_SELECTED_EVENT_RESULTS_FAILURE = 'FETCH_SELECTED_EVENT_RESULTS_FAILURE';

const FETCH_SELECTED_COMPETITOR_EVENT_DETAILS_START = 'FETCH_SELECTED_COMPETITOR_EVENT_DETAILS_START';
const FETCH_SELECTED_COMPETITOR_EVENT_DETAILS_SUCCESS = 'FETCH_SELECTED_COMPETITOR_EVENT_DETAILS_SUCCESS';
const FETCH_SELECTED_COMPETITOR_EVENT_DETAILS_FAILURE = 'FETCH_SELECTED_COMPETITOR_EVENT_DETAILS_FAILURE';

const FETCH_SELECTED_SERIES_DETAILS_START = 'FETCH_SELECTED_SERIES_DETAILS_START';
const FETCH_SELECTED_SERIES_DETAILS_SUCCESS = 'FETCH_SELECTED_SERIES_DETAILS_SUCCESS';
const FETCH_SELECTED_SERIES_DETAILS_FAILURE = 'FETCH_SELECTED_SERIES_DETAILS_FAILURE';

const FETCH_SELECTED_EVENT_COMPETITOR_BEST_LAPS_START = 'FETCH_SELECTED_EVENT_COMPETITOR_BEST_LAPS_START';
const FETCH_SELECTED_EVENT_COMPETITOR_BEST_LAPS_SUCCESS = 'FETCH_SELECTED_EVENT_COMPETITOR_BEST_LAPS_SUCCESS';
const FETCH_SELECTED_EVENT_COMPETITOR_BEST_LAPS_FAILURE = 'FETCH_SELECTED_EVENT_COMPETITOR_BEST_LAPS_FAILURE';

// Action Creators
const fetchCurrentEventStartAction = () => {
    return {
        type: FETCH_CURRENT_EVENT_START
    };
};
const fetchCurrentEventSuccessAction = (eventDetails) => {
    return {
        type: FETCH_CURRENT_EVENT_SUCCESS,
        payload: {
            eventDetails
        }
    };
};
const fetchCurrentEventFailureAction = (error) => {
    return {
        type: FETCH_CURRENT_EVENT_FAILURE,
        payload: {
            error
        }
    };
};

const setSelectedEventIdAction = (eventId, dispatch) => {
    return {
        type: SET_SELECTED_EVENT_ID,
        payload: {
            selectedEventId: eventId
        },
        dispatch
    };
};

const fetchSelectedEventStartAction = () => {
    return {
        type: FETCH_SELECTED_EVENT_DETAILS_START
    };
};
const fetchSelectedEventSuccessAction = (eventDetails) => {
    return {
        type: FETCH_SELECTED_EVENT_DETAILS_SUCCESS,
        payload: {
            eventDetails
        }
    };
};
const fetchSelectedEventFailureAction = (error) => {
    return {
        type: FETCH_SELECTED_EVENT_DETAILS_FAILURE,
        payload: {
            error
        }
    };
}

const fetchSelectedEventResultsStartAction = () => {
    return {
        type: FETCH_SELECTED_EVENT_RESULTS_START
    };
};
const fetchSelectedEventResultsSuccessAction = (eventResults) => {
    return {
        type: FETCH_SELECTED_EVENT_RESULTS_SUCCESS,
        payload: {
            eventResults
        }
    };
};
const fetchSelectedEventResultsFailureAction = (error) => {
    return {
        type: FETCH_SELECTED_EVENT_RESULTS_FAILURE,
        payload: {
            error
        }
    };
};

const fetchSelectedCompetitorEventDetailsStartAction = () => {
    return {
        type: FETCH_SELECTED_COMPETITOR_EVENT_DETAILS_START
    };
};
const fetchSelectedCompetitorEventDetailsSuccessAction = (competitorEventDetails) => {
    return {
        type: FETCH_SELECTED_COMPETITOR_EVENT_DETAILS_SUCCESS,
        payload: {
            competitorEventDetails
        }
    };
};
const fetchSelectedCompetitorEventDetailsFailureAction = (error) => {
    return {
        type: FETCH_SELECTED_COMPETITOR_EVENT_DETAILS_FAILURE,
        payload: {
            error
        }
    };
};

const fetchSelectedSeriesDetailsStartAction = () => {
    return {
        type: FETCH_SELECTED_SERIES_DETAILS_START
    }
};
const fetchSelectedSeriesDetailsSuccessAction = (seriesDetails) => {
    return {
        type: FETCH_SELECTED_SERIES_DETAILS_SUCCESS,
        payload: {
            seriesDetails
        }
    };
};
const fetchSelectedSeriesDetailsFailureAction = (error) => {
    return {
        type: FETCH_SELECTED_SERIES_DETAILS_FAILURE,
        payload: {
            error
        }
    };
};

const fetchSelectedEventCompetitorBestLapsStartAction = () => {
    return {
        type: FETCH_SELECTED_EVENT_COMPETITOR_BEST_LAPS_START
    };
};
const fetchSelectedEventCompetitorBestLapsSuccessAction = (eventCompetitorBestLaps) => {
    return {
        type: FETCH_SELECTED_EVENT_COMPETITOR_BEST_LAPS_SUCCESS,
        payload: {
            eventCompetitorBestLaps
        }
    };
};
const fetchSelectedEventCompetitorBestLapsFailureAction = (error) => {
    return {
        type: FETCH_SELECTED_EVENT_COMPETITOR_BEST_LAPS_FAILURE,
        payload: {
            error
        }
    };
};

// Thunks
export const fetchCurrentEventAndRedirect = (router) => {
    return dispatch => {
        dispatch(fetchCurrentEventStartAction());
        eventResource.getCurrentEvent()
            .then(data => {
                const { currentEvent } = data;
                if (currentEvent) {
                    dispatch(setSelectedEventIdAction(currentEvent.eventId));
                    dispatch(fetchCurrentEventSuccessAction(currentEvent));

                    router.push(`/events/${currentEvent.eventId}/results/`);
                }
            })
            .catch(error => {
                dispatch(fetchCurrentEventFailureAction(error))
            });
    };
};

export const fetchEventDetails = (eventId) => {
    return (dispatch, getState) => {
        const state = getState();

        if (eventId !== state.event.selectedEventId) {
            dispatch(setSelectedEventIdAction(eventId));
            dispatch(fetchSelectedEventStartAction());

            eventResource.getEvent(eventId)
                .then(data => {
                    dispatch(fetchSelectedEventSuccessAction(data.currentEvent));
                })
                .catch(error => {
                    dispatch(fetchSelectedEventFailureAction(error));
                });
        }
    };
};

export const fetchEventResults = (eventId, classShortCode) => {
    return dispatch => {
        dispatch(fetchSelectedEventResultsStartAction());

        eventResultsResource.getEventResults(eventId, classShortCode)
            .then(data => {
                dispatch(fetchSelectedEventResultsSuccessAction(data));
            })
            .catch(error => {
                dispatch(fetchSelectedEventResultsFailureAction(error));
            });
    };
};

export const fetchCompetitorEventDetails = (eventId, competitorId) => {
    return dispatch => {
        dispatch(fetchSelectedCompetitorEventDetailsStartAction());

        competitorDetailsResource.getCompetitorDetails(eventId, competitorId)
            .then(data => {
                dispatch(fetchSelectedCompetitorEventDetailsSuccessAction(data.competitorDetails));
            })
            .catch(error => {
                dispatch(fetchSelectedCompetitorEventDetailsFailureAction(error));
            });
    };
};

export const fetchSeriesDetails = (seriesId) => {
    return dispatch => {
        dispatch(fetchSelectedSeriesDetailsStartAction());

        seriesDetailsResource.getSeriesDetails(seriesId)
            .then(data => {
                dispatch(fetchSelectedSeriesDetailsSuccessAction(data.seriesDetails));
            })
            .catch(error => {
                dispatch(fetchSelectedSeriesDetailsFailureAction(error));
            });
    };
};

export const fetchEventCompetitorBestLaps = (eventId) => {
    return dispatch => {
        dispatch(fetchSelectedEventCompetitorBestLapsStartAction());

        eventCompetitorBestLapsResource.getEventCompetitorBestLaps(eventId)
            .then(data => {
                dispatch(fetchSelectedEventCompetitorBestLapsSuccessAction(data))
            })
            .catch(error => {
                dispatch(fetchSelectedEventCompetitorBestLapsFailureAction(error))
            });
    };
};

// Selectors
export const selectEventDetails = state => {
    return state.event.eventDetails;
};

export const selectEventResults = state => {
    return state.event.eventResults;
};

export const selectCompetitorEventDetails = state => {
    return state.event.competitorDetails;
};

export const selectSeriesDetails = state => {
    return state.event.seriesDetails;
};

export const selectEventCompetitorBestLaps = state => {
    return state.event.eventCompetitorBestLaps;
}

const initialState = {
    selectedEventId: undefined,
    selectedClassShortCode: undefined,
    selectedCompetitorId: undefined,
    eventDetails: {
        isLoading: false,
        data: undefined,
        error: undefined
    },
    eventResults: {
        isLoading: false,
        data: undefined,
        error: undefined
    },
    competitorDetails: {
        isLoading: false,
        data: undefined,
        error: undefined
    },
    seriesDetails: {
        isLoading: false,
        data: undefined,
        error: undefined
    },
    eventCompetitorBestLaps: {
        isLoading: false,
        data: undefined,
        error: undefined
    }
};

export default (state = initialState, action) => {
    const { type } = action;
    switch (type) {
        case FETCH_CURRENT_EVENT_START:
            return {
                ...state,
                eventDetails: {
                    isLoading: true,
                    data: undefined,
                    error: undefined
                }
            };
        case FETCH_CURRENT_EVENT_SUCCESS:
            return {
                ...state,
                eventDetails: {
                    isLoading: false,
                    data: action.payload.eventDetails,
                    error: undefined
                }
            };
        case FETCH_CURRENT_EVENT_FAILURE:
            return {
                ...state,
                eventDetails: {
                    isLoading: false,
                    data: undefined,
                    error: action.payload.error
                }
            };
        case SET_SELECTED_EVENT_ID:
            return {
                ...state,
                selectedEventId: action.payload.selectedEventId
            };
        case FETCH_SELECTED_EVENT_DETAILS_START:
            return {
                ...state,
                eventDetails: {
                    isLoading: true,
                    data: undefined,
                    error: undefined
                }
            };
        case FETCH_SELECTED_EVENT_DETAILS_SUCCESS:
            return {
                ...state,
                eventDetails: {
                    isLoading: false,
                    data: action.payload.eventDetails,
                    error: undefined
                }
            };
        case FETCH_SELECTED_EVENT_DETAILS_FAILURE:
            return {
                ...state,
                eventDetails: {
                    isLoading: false,
                    data: undefined,
                    error: action.payload.error
                }
            };
        case FETCH_SELECTED_EVENT_RESULTS_START:
            return {
                ...state,
                eventResults: {
                    isLoading: true,
                    data: undefined,
                    error: undefined
                }
            };
        case FETCH_SELECTED_EVENT_RESULTS_SUCCESS:
            return {
                ...state,
                eventResults: {
                    isLoading: false,
                    data: action.payload.eventResults,
                    error: undefined
                }
            };
        case FETCH_SELECTED_EVENT_RESULTS_FAILURE:
            return {
                ...state,
                eventResults: {
                    isLoading: false,
                    data: undefined,
                    error: action.payload.error
                }
            };
        case FETCH_SELECTED_COMPETITOR_EVENT_DETAILS_START:
            return {
                ...state,
                competitorDetails: {
                    isLoading: true,
                    data: undefined,
                    error: undefined
                }
            };
        case FETCH_SELECTED_COMPETITOR_EVENT_DETAILS_SUCCESS:
            return {
                ...state,
                competitorDetails: {
                    isLoading: false,
                    data: action.payload.competitorEventDetails,
                    error: undefined
                }
            };
        case FETCH_SELECTED_COMPETITOR_EVENT_DETAILS_FAILURE:
            return {
                ...state,
                competitorDetails: {
                    isLoading: false,
                    data: undefined,
                    error: action.payload.error
                }
            };
        case FETCH_SELECTED_SERIES_DETAILS_START:
            return {
                ...state,
                seriesDetails: {
                    isLoading: true,
                    data: undefined,
                    error: undefined
                }
            };
        case FETCH_SELECTED_SERIES_DETAILS_SUCCESS:
            return {
                ...state,
                seriesDetails: {
                    isLoading: false,
                    data: action.payload.seriesDetails,
                    error: undefined
                }
            };
        case FETCH_SELECTED_SERIES_DETAILS_FAILURE:
            return {
                ...state,
                seriesDetails: {
                    isLoading: false,
                    data: undefined,
                    error: action.payload.error
                }
            };
        case FETCH_SELECTED_EVENT_COMPETITOR_BEST_LAPS_START:
            return {
                ...state,
                eventCompetitorBestLaps: {
                    isLoading: true,
                    data: undefined,
                    error: undefined
                }
            };
        case FETCH_SELECTED_EVENT_COMPETITOR_BEST_LAPS_SUCCESS:
            return {
                ...state,
                eventCompetitorBestLaps: {
                    isLoading: false,
                    data: action.payload.eventCompetitorBestLaps,
                    error: undefined
                }
            };
        case FETCH_SELECTED_EVENT_COMPETITOR_BEST_LAPS_FAILURE:
            return {
                ...state,
                eventCompetitorBestLaps: {
                    isLoading: false,
                    data: undefined,
                    error: action.payload.error
                }
            };
        default:
            return state;
    }
};
