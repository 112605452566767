import React from 'react';
import { map } from 'lodash';
import { Col, Container, Row } from 'react-bootstrap';
import SeasonDetails from './SeasonDetails';

const SeriesDetails = ({ seriesDetails }) => {
    return (
        <Container fluid>
            <Row>
                <Col sm={12}><h1>{seriesDetails.name}</h1></Col>
            </Row>
            {map(seriesDetails.seasons, season => {
                return (
                    <SeasonDetails seasonDetails={season} key={season.id}/>
                );
            })}
        </Container>
    );
};

export default SeriesDetails;
