import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import formatLapTime from '../../helpers/lapTimeFormatter';

const CompetitorResultSummary = ({
    bestTimeInSeconds,
    classTargetTimeInSeconds,
    overallTargetTimeInSeconds,
}) => {
    const boldStyle = {
        fontWeight: 'bold',
    };

    return (
        <Container fluid>
            <Row style={{ margin: '10px 0 10px 0' }}>
                <Col sm={4} style={{ textAlign: 'left' }}>
                    <span style={boldStyle}>Best Lap:</span> {formatLapTime(bestTimeInSeconds)}
                </Col>
                <Col sm={4} style={{ textAlign: 'center' }}>
                    <span style={boldStyle}>Class Target:</span> {formatLapTime(classTargetTimeInSeconds)} {bestTimeInSeconds.toFixed(3) > classTargetTimeInSeconds.toFixed(3) ? `(+${(bestTimeInSeconds - classTargetTimeInSeconds).toFixed(3)}s)` : "" }
                </Col>
                <Col sm={4} style={{ textAlign: 'right' }}>
                    <span style={boldStyle}>Overall Target:</span> {formatLapTime(overallTargetTimeInSeconds)} {bestTimeInSeconds.toFixed(3) > overallTargetTimeInSeconds.toFixed(3) ? `(+${(bestTimeInSeconds - overallTargetTimeInSeconds).toFixed(3)}s)` : ""}
                </Col>
            </Row>
        </Container>
    );
};

export default CompetitorResultSummary;
