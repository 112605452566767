import getSeriesId from '../helpers/urlSeriesMapping'

const resource = {
    getCurrentEvent() {
        const seriesId = getSeriesId();

        return fetch(`/api/series/${seriesId}/currentevent`)
                  .then(response => response.json())
                  .then(currentEvent => Promise.resolve({ currentEvent }));
    },
    getEvent(eventId) {
        return fetch(`/api/event/${eventId}/details`)
                  .then(response => response.json())
                  .then(currentEvent => Promise.resolve({ currentEvent }));
    },
};

export default resource;
