import React, { useEffect } from 'react';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Route } from 'react-router-dom';
import { fetchCurrentEventAndRedirect } from '../reducers';

const RecentEventRoute = compose(
    withRouter,
    connect(
        null,
        {
            fetchCurrentEventAndRedirect,
        }
    )
)(({ path, component, exact, fetchCurrentEventAndRedirect, history, ...rest }) => {
    useEffect(() => {
        fetchCurrentEventAndRedirect(history);
    }, [fetchCurrentEventAndRedirect, history]);

    return <Route exact={exact} path={path} component={component} {...rest} />;
});

export default RecentEventRoute;
