import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { fetchCompetitorEventDetails, selectCompetitorEventDetails, selectEventDetails } from '../../reducers';
import CompetitorResults from './CompetitorResults';
import LoadingSpinner from '../LoadingSpinner';

const CompetitorDetailsContainer = connect(
    state => {
        return {
            competitorDetails: selectCompetitorEventDetails(state),
            eventDetails: selectEventDetails(state)
        };
    },
    {
        fetchCompetitorEventDetails
    }
)(({ competitorDetails, fetchCompetitorEventDetails, eventId, competitorId, eventDetails, ...rest }) => {

    useEffect(() => {
        fetchCompetitorEventDetails(eventId, competitorId);
    }, [fetchCompetitorEventDetails, eventId, competitorId]);

    return competitorDetails.isLoading || typeof competitorDetails.data === 'undefined' || eventDetails.isLoading || typeof eventDetails.data === 'undefined'
        ? <LoadingSpinner size={30} color={'#bc0000'} />
        : <CompetitorResults competitorSessionsData={competitorDetails.data} shouldDisplayPaxFields={eventDetails.data.displayPaxFieldsOnLiveTiming} />;
});

export default CompetitorDetailsContainer;