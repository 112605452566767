import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { css } from '@emotion/react';
import { PacmanLoader } from 'react-spinners';

const override = css`
    display: block;
    margin: 0 auto;
`;

const LoadingSpinner = ({ size, color }) => {
    return (
        <Container fluid>
            <Row>
                <Col sm={12} style={{ paddingTop: '50px' }}>
                    <PacmanLoader css={override} sizeUnit={"px"} size={size} color={color} />
                </Col>
            </Row>
        </Container>
    );
};

export default LoadingSpinner;
