import React from 'react';
import { map } from 'lodash';
import { Col, Container, Row } from 'react-bootstrap';
import CompetitorPersonalDetails from './CompetitorPersonalDetails';
import CompetitorResultSummary from './CompetitorResultSummary';
import CompetitorSession from './CompetitorSession';

const CompetitorResults = ({ competitorSessionsData, shouldDisplayPaxFields }) => {
    return (
        <Container fluid>
            <Row style={{ border: '1px solid' }}>
                <Col sm={12}>
                    <CompetitorPersonalDetails
                        name={competitorSessionsData.competitorName}
                        carNumber={competitorSessionsData.competitorCarNumber}
                        car={competitorSessionsData.competitorCar}
                        performanceIndex={competitorSessionsData.competitorPerformanceIndex}
                        paxFactor={competitorSessionsData.competitorPaxFactor}
                        shouldDisplayPaxFields={shouldDisplayPaxFields}
                    />
                </Col>
            </Row>
            <Row style={{ border: '1px solid' }}>
                <Col sm={12}>
                    <CompetitorResultSummary
                        bestTimeInSeconds={competitorSessionsData.bestTimeInSeconds}
                        classTargetTimeInSeconds={competitorSessionsData.classTargetTimeInSeconds}
                        overallTargetTimeInSeconds={competitorSessionsData.overallTargetTimeInSeconds}
                    />
                </Col>
            </Row>
            <Row style={{ border: '1px solid' }}>
                {map(competitorSessionsData.sessions, competitorSession => {
                    const { sessionId } = competitorSession;
                    return (
                        <Col key={sessionId} sm={12}>
                            <CompetitorSession competitorSession={competitorSession} shouldDisplayPaxFields={shouldDisplayPaxFields} />
                        </Col>
                    );
                })}
            </Row>
        </Container>
    );
};

export default CompetitorResults;
