import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/css/bootstrap-theme.css';
import './index.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose } from 'redux';
import { BrowserRouter } from 'react-router-dom';
import thunk from 'redux-thunk';
import registerServiceWorker from './registerServiceWorker';
import rootReducer from './rootReducer';
import { ThemeProvider } from 'styled-components';
import Router from './Router';

const theme = {
    white: '#fff',
    black: '#000',
    red: '#ba0000'
};

const store = createStore(rootReducer, compose(applyMiddleware(thunk)));

/*
 * I added this here so I could check what is in the reducer during development.
 * You can either add an environment check so it's not exposed in a live environment
 * or remove it all together.
 */
window.store = store;

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');

ReactDOM.render(
    <BrowserRouter basename={baseUrl}>
        <Provider store={store}>
            <ThemeProvider theme={theme}>{Router}</ThemeProvider>
        </Provider>
    </BrowserRouter>,
    rootElement
);

registerServiceWorker();
