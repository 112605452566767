import React from 'react';
import { map } from 'lodash';
import { Col, Container, Row } from 'react-bootstrap';
import EventDetails from './EventDetails';

const SeasonDetails = ({ seasonDetails }) => {
    const seasonHasEvents = seasonDetails.events && seasonDetails.events.length;
    return (
        <Container fluid>
            {/* //TODO: make these collapsable */}
            <Row>
                <Col sm={12}><h3>{seasonDetails.name}</h3></Col>
            </Row>
            <Row>
                {
                    seasonHasEvents
                        ? map(seasonDetails.events, eventDetails => {
                            return <EventDetails eventDetails={eventDetails} key={eventDetails.eventId} />;
                        })
                        : <Col style={{textAlign: 'center'}}>No events to view for this season yet.</Col>                    
                }
            </Row>
        </Container>
    );
};

export default SeasonDetails;
