import React, { Fragment } from 'react';
import { useTable, useSortBy } from 'react-table';
import styled from 'styled-components';
import formatLapTime from '../../helpers/lapTimeFormatter';
import { Link } from 'react-router-dom';

const TextLink = styled(Link)`
    color: ${({ theme }) => theme.red};

    &:hover {
        cursor: pointer;
        text-decoration: underline;
        color: ${({ theme }) => theme.red};
    }

    &:focus {
        color: ${({ theme }) => theme.red};
    }
`;

const numericSortFunction = (a, b, property) => Number(a.original[property]) - Number(b.original[property]);

const getSortBy = (shouldDisplayPaxFields) => {
    return shouldDisplayPaxFields
        ? [
            { id: 'bestTimeScoreSortable', desc: true },
            { id: 'practiceBestPaxTimeSortable', desc: false },
            { id: 'competitorLastName', desc: false }
        ]
        : [
            { id: 'competitionBestRawTimeSortable', desc: false },
            { id: 'practiceBestRawTimeSortable', desc: false },
            { id: 'competitorLastName', desc: false }
        ];
};

const getColumns = (eventId, shouldDisplayPaxFields) => {
    const columns = [
        {
            Header: 'Competitor',
            columns: [
                {
                    Header: 'Name',
                    Cell: props =>
                        <Fragment>
                            <TextLink to={`/events/${eventId}/competitor/${props.row.original.competitorId}/details`}>
                                {props.row.original.competitorFirstName} {props.row.original.competitorLastName}
                            </TextLink>
                            {
                                shouldDisplayPaxFields
                                    ? <span>- (PI: {props.row.original.performanceIndex}, PAX: {props.row.original.paxFactor})</span>
                                    : ''
                            }
                        </Fragment>
                },
                {
                    Header: 'Car',
                    Cell: props => <Fragment>#{props.row.original.carNumber} - {props.row.original.carMake} {props.row.original.carModel}</Fragment>
                },
                {
                    Header: 'Class',
                    accessor: 'classMinPerformanceIndex',
                    sortType: numericSortFunction,
                    Cell: props => <Fragment>{props.row.original.classShortCode}</Fragment>
                }
            ]
        },
        {
            Header: 'Practice',
            columns: [
                {
                    Header: 'Best Raw',
                    accessor: 'practiceBestRawTimeSortable',
                    sortType: numericSortFunction,
                    Cell: props => <Fragment>{props.row.original.practiceBestRawTime ? formatLapTime(props.row.original.practiceBestRawTime) : '-'}</Fragment>
                },
                {
                    Header: 'Best PAX',
                    accessor: 'practiceBestPaxTimeSortable',
                    sortType: numericSortFunction,
                    Cell: props => <Fragment>{props.row.original.practiceBestPaxTime ? props.row.original.practiceBestPaxTime.toFixed(3) : '-'}</Fragment>
                }
            ]
        },
        {
            Header: 'Competition',
            columns: [
                {
                    Header: 'Best Raw',
                    accessor: 'competitionBestRawTimeSortable',
                    sortType: numericSortFunction,
                    Cell: props => <Fragment>{props.row.original.competitionBestRawTime ? formatLapTime(props.row.original.competitionBestRawTime) : '-'}</Fragment>
                },
                {
                    Header: 'Best PAX',
                    accessor: 'competitionBestPaxTimeSortable',
                    sortType: numericSortFunction,
                    Cell: props => <Fragment>{props.row.original.competitionBestPaxTime ? props.row.original.competitionBestPaxTime.toFixed(3) : '-'}</Fragment>
                },
                {
                    Header: 'Score',
                    accessor: 'bestTimeScoreSortable',
                    sortType: numericSortFunction,
                    Cell: props => <Fragment>{props.row.original.bestTimeScore ? props.row.original.bestTimeScore.toFixed(3) : '-'}{props.row.original.isExhibition ? '*' : ''}{props.row.original.isPenalizedBySteward ? '!' : ''}</Fragment>
                }
            ]
        }
    ];

    if (!shouldDisplayPaxFields) {
        columns[1].columns.pop();
        let score = columns[2].columns.pop();
        columns[2].columns.pop();
        columns[2].columns.push(score);
    }

    return columns;
}

const Styles = styled.div`
  padding: 1rem;

  table {
    border-spacing: 0;
    border: 1px solid #DDDDDD;
    width: 100%;

    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th {
      margin-right: 0;
      padding: 0.5rem 2rem;
      text-align: center;
      background-color: #EEEEEE;
      border-bottom: 1px solid #DDDDDD;
      border-right: 1px solid #DDDDDD;
    },
    td {
      margin: 0;
      padding: 1rem;
      border-bottom: 1px solid #DDDDDD;
      border-right: 1px solid #DDDDDD;
    }
  }
`;

const Table = ({ columns, data, shouldDisplayPaxFields }) => {
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable(
        {
            columns,
            data,
            initialState: {
                sortBy: getSortBy(shouldDisplayPaxFields)
            }
        },
        useSortBy
    )

    return (
        <>
            <table {...getTableProps()}>
                <thead>
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => (
                                // Add the sorting props to control sorting. For this example
                                // we can add them into the header props
                                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                    {column.render('Header')}
                                    {/* Add a sort direction indicator */}
                                    <span>
                                        {column.isSorted
                                            ? column.isSortedDesc
                                                ? ' 🔽'
                                                : ' 🔼'
                                            : ''}
                                    </span>
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {rows.map(
                        (row, i) => {
                            prepareRow(row);
                            return (
                                <tr {...row.getRowProps()}>
                                    {row.cells.map(cell => {
                                        return (
                                            <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                        )
                                    })}
                                </tr>
                            )
                        }
                    )}
                </tbody>
            </table>
        </>
    )
};

const EventOverallTimes = ({ eventId, eventCompetitorBestLaps, shouldDisplayPaxFields }) => {
    return (
        <Styles>
            <Table columns={getColumns(eventId, shouldDisplayPaxFields)} data={eventCompetitorBestLaps.eventCompetitorBestLaps} shouldDisplayPaxFields={shouldDisplayPaxFields} />
        </Styles>
    );
};

export default EventOverallTimes;
