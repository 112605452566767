import React from 'react';
import styled from 'styled-components';
import { map } from 'lodash';
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import CompetitorResult from './CompetitorResult';

const TextLink = styled(Link)`
    color: ${({ theme }) => theme.red};

    &:hover {
        cursor: pointer;
        text-decoration: underline;
        color: ${({ theme }) => theme.red};
    }
`;

const ClassResult = ({ classResult, eventId, shouldDisplayPaxFields }) => {
    return (
        <Container fluid>
            <Row>
                <Col sm={12} style={{ border: '1px solid', fontSize: '150%' }}>
                    <TextLink to={`/events/${eventId}/results/${classResult.classShortCode}`}>
                        {classResult.className}
                    </TextLink>
                </Col>
            </Row>
            <Row>
                <Col sm={12}>
                    {map(classResult.competitorResults, competitorResult => {
                        return (
                            <Row key={competitorResult.competitorId}>
                                <Col sm={12}>
                                    <CompetitorResult eventId={eventId} competitorResult={competitorResult} shouldDisplayPaxFields={shouldDisplayPaxFields} />
                                </Col>
                            </Row>
                        );
                    })}
                </Col>
            </Row>
        </Container>
    );
};

export default ClassResult;
