import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { fetchSeriesDetails, selectSeriesDetails } from '../../reducers';
import SeriesDetails from './SeriesDetails';
import LoadingSpinner from '../LoadingSpinner';

const SeriesDetailsContainer = connect(
    state => {
        return {
            seriesDetails: selectSeriesDetails(state)
        };
    },
    {
        fetchSeriesDetails
    }
)(({ seriesDetails, fetchSeriesDetails, seriesId, ...rest }) => {

    useEffect(() => {
        fetchSeriesDetails(seriesId);
    }, [fetchSeriesDetails, seriesId]);

    return seriesDetails.isLoading || typeof seriesDetails.data === 'undefined'
        ? <LoadingSpinner size={30} color={'#bc0000'} />
        : <SeriesDetails seriesDetails={seriesDetails.data} />;
});

export default SeriesDetailsContainer;