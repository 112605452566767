import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import EventOverallTimes from './EventOverallTimes';
import { fetchEventCompetitorBestLaps, selectEventCompetitorBestLaps, selectEventDetails } from '../../reducers';
import LoadingSpinner from '../LoadingSpinner';

const EventOverallTimesContainer = connect(
    state => {
        return {
            eventCompetitorBestLaps: selectEventCompetitorBestLaps(state),
            eventDetails: selectEventDetails(state)
        };
    },
    {
        fetchEventCompetitorBestLaps
    }
)(({ eventCompetitorBestLaps, fetchEventCompetitorBestLaps, eventId, eventDetails, ...rest }) => {

    useEffect(() => {
        fetchEventCompetitorBestLaps(eventId);
    }, [fetchEventCompetitorBestLaps, eventId]);

    return eventCompetitorBestLaps.isLoading || typeof eventCompetitorBestLaps.data === 'undefined' || eventDetails.isLoading || typeof eventDetails.data === 'undefined'
        ? <LoadingSpinner size={30} color={'#bc0000'} />
        : <EventOverallTimes eventId={eventId} eventCompetitorBestLaps={eventCompetitorBestLaps.data} shouldDisplayPaxFields={eventDetails.data.displayPaxFieldsOnLiveTiming} />;
});

export default EventOverallTimesContainer;
