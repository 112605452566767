import React from "react";
import { Col, Container, Row } from "react-bootstrap";

const SimpleLayout = ({ children }) => {
  return (
    <div style={{ margin: "0 auto", maxWidth: "1200px" }}>
      <Container fluid>
        <Row>
          <Col sm={12}>{children}</Col>
        </Row>
      </Container>
    </div>
  );
};

export default SimpleLayout;
