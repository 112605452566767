import React from 'react';
import EventOverallTimesContainer from './EventOverallTimesContainer';
import Layout from '../Layout';

const EventOverallTimesPage = ({ match }) => {
    const { params } = match;
    const { eventId } = params;
    return (
        <Layout eventId={eventId}>
            <EventOverallTimesContainer eventId={eventId} />
        </Layout>
    );
};

export default EventOverallTimesPage;