import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const TextLink = styled(Link)`
    color: ${({ theme }) => theme.red};

    &:hover {
        cursor: pointer;
        text-decoration: underline;
        color: ${({ theme }) => theme.red};
    }
`;

const EventDetails = ({ eventDetails }) => {
    return (
        <Container fluid>
            <Row>
                <Col sm={3}>
                    {
                        eventDetails.enableLink
                            ? <TextLink to={`/events/${eventDetails.eventId}/results`}>{eventDetails.eventName}</TextLink>
                            : eventDetails.eventName
                    }
                </Col>
                <Col sm={6}>
                    {
                        eventDetails.enableLink
                            ? <TextLink to={`/events/${eventDetails.eventId}/results`}>{eventDetails.trackName} {eventDetails.trackLayoutName}</TextLink>
                            : eventDetails.trackName + ' ' + eventDetails.trackLayoutName
                    }
                    
                </Col>
                <Col sm={3}>
                    {
                        eventDetails.enableLink
                            ? <TextLink to={`/events/${eventDetails.eventId}/results`}>{moment(new Date(eventDetails.eventDate)).format('MMMM Do, YYYY')}</TextLink>
                            : moment(new Date(eventDetails.eventDate)).format('MMMM Do, YYYY')
                    }
                </Col>
            </Row>
        </Container>
    );
};

export default EventDetails;
