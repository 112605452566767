import React from 'react';
import styled from 'styled-components';
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import moment from 'moment';
import sponsorLogo from '../images/automoves-logo-black-blue.jpg';

const bigFontStyle = {
    fontSize: '150%',
};

const TextLink = styled(Link)`
    color: ${({ theme }) => theme.red};

    &:hover {
        cursor: pointer;
        text-decoration: underline;
        color: ${({ theme }) => theme.red};
    }

    &:focus {
        color: ${({ theme }) => theme.red};
    }
`;

const Header = ({ eventId, seasonName, eventName, trackName, trackLayoutName, eventDate }) => {
    return (
        <Container fluid>
            <Row>
                <Col sm={12}>
                    {
                        seasonName.includes("OTA")
                            ? <a href="https://www.automoves.com/" target="_blank" rel="noopener noreferrer"><img src={sponsorLogo} alt="" width="100%" /></a>
                            : <img src="" alt="" />
                    }
                </Col>
            </Row>
            <Row>
                <Col sm={5} style={{ textAlign: 'left' }}>
                    <span style={bigFontStyle}>
                        <TextLink to={`/events/${eventId}/results/`}>
                            {seasonName} {eventName}
                        </TextLink>
                    </span>
                </Col>
                <Col sm={5} style={{ textAlign: 'center' }}>
                    <span style={bigFontStyle}>
                        {trackName} {trackLayoutName}
                    </span>
                </Col>
                <Col sm={2} style={{ textAlign: 'right' }}>
                    <span style={bigFontStyle}>
                        {moment(new Date(eventDate)).format('MMMM Do, YYYY')}
                    </span>
                </Col>
            </Row>
            <Row>
                <Col sm={10}></Col>
                <Col sm={2} style={{ textAlign: 'right' }}>
                    <TextLink to={`/events/${eventId}/competitorbestlaps`}>
                        View Overall Best Laps
                    </TextLink>
                </Col>
            </Row>
        </Container>
    );
};

export default Header;
