import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import EventResultsPage from './components/EventResults/EventResultsPage';
import SeriesDetailsPage from './components/SeriesDetails/SeriesDetailsPage';
import CompetitorDetailsPage from './components/CompetitorDetails/CompetitorDetailsPage';
import EventOverallTimesPage from './components/EventOverallTimes/EventOverallTimesPage';
import FourZeroFour from './components/404';

// Routes
import RecentEventRoute from './routes/RecentEventRoute';

export default (
    <Router>
        <Switch>
            <RecentEventRoute path="/" exact={true} component={EventResultsPage} />
            <Route exact={true} path="/events/:eventId/results/:classShortCode?" component={EventResultsPage} />
            <Route exact={true} path="/events/:eventId/competitor/:competitorId/details" component={CompetitorDetailsPage} />
            <Route exact={true} path="/series/:seriesId/details" component={SeriesDetailsPage} />
            <Route exact={true} path="/events/:eventId/competitorbestlaps" component={EventOverallTimesPage} />
            <Route component={FourZeroFour} />
        </Switch>
    </Router>
);
