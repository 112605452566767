import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import EventResults from './EventResults';
import { fetchEventResults, selectEventResults, selectEventDetails } from '../../reducers';
import LoadingSpinner from '../LoadingSpinner';

const EventResultsContainer = connect(
    state => {
        return {
            eventResults: selectEventResults(state),
            eventDetails: selectEventDetails(state)
        };
    },
    {
        fetchEventResults
    }
)(({ eventResults, fetchEventResults, eventId, classShortCode, eventDetails, ...rest }) => {

    useEffect(() => {
        fetchEventResults(eventId, classShortCode);
    }, [fetchEventResults, eventId, classShortCode]);

    return eventResults.isLoading || typeof eventResults.data === 'undefined' || eventDetails.isLoading || typeof eventDetails.data === 'undefined'
        ? <LoadingSpinner size={30} color={'#bc0000'} />
        : <EventResults results={eventResults.data.eventResults.classResults} eventId={eventId} shouldDisplayPaxFields={eventDetails.data.displayPaxFieldsOnLiveTiming} />;
});

export default EventResultsContainer;
