
const urlSeriesMappingConfig = {
    'localhost': 1,
    'otaltdev.thewpp.ca': 1,
    'livetiming.time-attack.ca': 1,
    'livetiming.ontariotimeattack.ca': 1,
    'livetiming.ontariotimeattack.com': 1,
    'nstadev.thewpp.ca': 2,
    'nstiming.time-attack.ca': 2,
    'nstiming.ontariotimeattack.ca': 2,
    'nstiming.ontariotimeattack.com': 2
};

const getSeriesId = () => {
    return urlSeriesMappingConfig[window.location.hostname] ? urlSeriesMappingConfig[window.location.hostname] : 1;
};

export default getSeriesId;