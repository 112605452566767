import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';

const CompetitorPersonalDetails = ({ name, carNumber, car, paxFactor, performanceIndex, shouldDisplayPaxFields }) => {
    const boldStyle = {
        fontWeight: 'bold',
    };
    const bigFontStyle = {
        fontSize: '125%',
    };
    return (
        <Container fluid>
            <Row style={{ margin: '10px 0 10px 0' }}>
                <Col sm={4} style={{ textAlign: 'left' }}>
                    <span style={bigFontStyle}>{name}</span>
                </Col>
                <Col sm={4} style={{ textAlign: 'left' }}>
                    <span style={boldStyle}>#{carNumber}</span> {car}
                </Col>
                {
                    shouldDisplayPaxFields
                        ?
                        <Col sm={2} style={{ textAlign: 'center' }}>
                            <span style={boldStyle}>Pax Factor:</span> {paxFactor}
                        </Col>
                        : ''
                }
                {
                    shouldDisplayPaxFields
                        ? 
                        <Col sm={2} style={{ textAlign: 'right' }}>
                            <span style={boldStyle}>PI:</span> {performanceIndex}
                        </Col>
                        : ''
                }
            </Row>
        </Container>
    );
};

export default CompetitorPersonalDetails;
