import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import HeaderContainer from './HeaderContainer';
import getSeriesId from '../helpers/urlSeriesMapping';

const TextLink = styled(Link)`
    font-weight: bold;
    color: ${({ theme }) => theme.red};

    &:hover {
        cursor: pointer;
        text-decoration: none;
        color: ${({ theme }) => theme.red};
    }
`;

const Layout = ({ eventId, children }) => {
    const seriesId = getSeriesId();

    return (
        <div style={{ margin: "0 auto", maxWidth: "1200px" }}>
            <Container fluid>
                <Row>
                    <Col sm={12} style={{ textAlign: "right" }}>
                        <TextLink to={`/series/${seriesId}/details/`}>
                            View All Events
                        </TextLink>
                    </Col>
                </Row>
                <Row>
                    <Col sm={12}>
                        <HeaderContainer eventId={eventId} />
                    </Col>
                </Row>
                <Row>
                    <Col sm={12}>{children}</Col>
                </Row>
                <Row>
                    <Col sm={12}>
                        * - Exhibition Competitor ! - Stewards Decision Penalty
                    </Col>
                </Row>
            </Container>
        </div>
        );
};

export default Layout;
