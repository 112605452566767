import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { fetchEventDetails, selectEventDetails } from '../reducers';
import Header from './Header';
import LoadingSpinner from './LoadingSpinner';

const HeaderContainer = connect(
    state => {
        return {
            eventDetails: selectEventDetails(state)
        };
    },
    {
        fetchEventDetails
    }
)(({ eventDetails, fetchEventDetails, eventId, ...rest }) => {

    useEffect(() => {
        fetchEventDetails(eventId);
    }, [fetchEventDetails, eventId]);

    return eventDetails.isLoading || typeof eventDetails.data === 'undefined'
        ? <LoadingSpinner size={10} color={'#bc0000'} />
        : (
        <Header
            eventId={eventDetails.data.eventId}
            seasonName={eventDetails.data.seasonName}
            eventName={eventDetails.data.eventName}
            trackName={eventDetails.data.trackName}
            trackLayoutName={eventDetails.data.trackLayoutName}
            eventDate={eventDetails.data.eventDate}
        />
    );
});

export default HeaderContainer;