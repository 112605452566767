import React from 'react';
import CompetitorDetailsContainer from './CompetitorDetailsContainer';
import Layout from '../Layout';

const CompetitorDetailsPage = ({ match }) => {
    const { params } = match;
    const { eventId, competitorId } = params;
    return (
        <Layout eventId={eventId}>
            <CompetitorDetailsContainer eventId={eventId} competitorId={competitorId} />
        </Layout>
    );
};

export default CompetitorDetailsPage;